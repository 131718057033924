/* Add this style to your CSS */
.patient-card {
    border: 1px solid #ccc; /* Border color */
    border-radius: 8px; /* Border radius for rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle elevation effect */
    transition: box-shadow 0.3s ease; /* Smooth transition for box shadow changes */
  
    /* Add padding and margin to create spacing between cards */
    padding: 15px;
    margin-bottom: 20px;
  
    /* Specify a maximum width for the card if needed */
    max-width: 300px;
  
    /* Center text content */
    text-align: center;
  }
  
  .patient-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Change box shadow on hover for an interactive effect */
  }
  
.doctor-availability-container {
  display: flex;
  justify-content: space-between;
}

.calendar-container {
  width: 80%; /* Adjust the width of the calendar */
  margin-right: 10px; /* Add margin to create space between the calendar and other elements */
}

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 50px; /* Align checkbox vertically */
}


.availability-details-container {
  width: 25%;
}

.calendar-container .react-calendar {
  width: 100%;
  height: 700px; /* Adjust the height of the calendar */
}

/* Add a custom style for the selected date in the calendar */
.react-calendar__tile--active {
  background-color: #e0f7fa; /* Change the background color for the selected date */
  color: #000; /* Change the text color for the selected date */
}

/* Highlight today's date */
.react-calendar__tile--now {
  background-color: #ffcdd2; /* Change the background color for today's date */
  color: #000; /* Change the text color for today's date */
}

.selected-date {
  background-color: #a5d6a7; /* Change the background color for the selected date */
  color: #000; /* Change the text color for the selected date */
}

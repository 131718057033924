

.bckground-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../images/bckimage.webp'); /* Replace with your image path */
  background-size: cover;
  z-index: -1;
  
}

.formhead {
  max-width: 400px; /* Set the maximum width as needed */
  padding: 20px; /* Add padding for better spacing */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Add a semi-transparent background */
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
}

/* DoctorAppointmentPage.css */

.filter-dropdown {
    margin-bottom: 20px;
  }
  
  .appointment-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between cards */
    justify-content: space-between; /* Distribute space between cards */
  }
  
  .appointment-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Add margin at the bottom of each card */
  }
  
  .appointment-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .appointment-details {
    padding: 15px;
  }
  
  .appointment-details h3 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .appointment-details p {
    margin: 5px 0;
  }
 

.space-after-dropdown {
    margin-bottom: 70px; /* Adjust the margin as needed */
  }
  /* Add this to DoctorAppointmentPage.css */

.details-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .details-button:hover {
    background-color: #0056b3;
  }
  
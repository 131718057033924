.first-row {
    width: 100%;
    display: flex;
    height: 50%;
    margin-bottom: 100px;
    margin-top: 20px;
  }
  
  .first-row img {
    width: 80vw;
    height: 40vh;
  }
  
  .second-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    margin: 20px 0;
  }
  
  .column {
    margin: 0 10px;
    margin-right: 100px;
  }
  
  .column > div {
    margin-bottom: 10px;
  }
  
  .column label {
    display: block;
    margin-bottom: 5px;
  }
  
  .column input,
  .column select,
  .column textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .column textarea {
    height: 80px;
  }
  
  .react-calendar {
    max-width: 600px;
    font-size: 14px;
  }
  
  .react-calendar .working-date {
    background-color: #7fff7f;
    border-radius: 50%;
  }
  
  /* Add this style to your existing CSS or create a new CSS file */
.confirm-button {
    background-color: #4caf50; /* Green background color */
    color: white; /* White text color */
    padding: 10px 20px; /* Padding around the text */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor style on hover */
    border: none; /* Remove border */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
  
  .confirm-button:hover {
    background-color: #45a049; /* Darker green color on hover */
  }
  
.main {
  padding: 10px;
  height: 100vh;
}

.layout {
  display: flex;
}

.sidebar {
  min-height: 100%;
  width: 300px;
  border-radius: 5px;
  background-color: #c3c9c5;
  box-shadow: 0 0 2px gray;
  margin-right: 20px;
  color: #2eb1be;
}
.content {
  width: 100%;
  height: 100%;
}

.avatar-img {
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
}

.header {
  height: 10vh;
  margin-bottom: 20px;
  box-shadow: 0 0 2px gray;
  background-color: #2eb1be;
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  padding: 0 20px; /* Add padding to the header */
}

.logo-container {
  padding: 10px;
  background-color: #c3c9c5;
}

.user-actions {
  display: flex;
  align-items: center;
}

.user-welcome {
  color: white;
  font-size: 1.2rem;
  margin: 10px;
}

.user-dropdown {
  margin-left: auto; /* Move the dropdown to the right */
  color: white;
}

.body {
  height: 185vh;
  margin-bottom: 20px;
  box-shadow: 0 0 2px gray;
  background-color: white;
}

.logo-img {
  font-size: 1.5rem;
  text-align: center;
  margin: 20px 0px;
  max-height: 200%;
  max-width: 100%;
}

.menu {
  margin-top: 50px;
}

.menu-item {
  margin-top: 30px;
}

.menu-item a {
  color: #2eb1be;
  text-decoration: none;
  font-size: 1.2rem;
}

.menu-item i {
  font-size: 1.2rem;
  margin: 0 15px;
}

.active {
  background-color: white;
  padding: 10px;
}

.active a,
.active i {
  color: rgb(51, 1, 1);
}

.notification-icon {
  display: flex;
  align-items: center;
  position: relative;
}
.red-symbol {
  position: absolute; /* Set absolute positioning for the red symbol */
  top: 50%; /* Adjust the vertical position */
  left: 50%; /* Adjust the horizontal position */
  transform: translate(-50%, -50%); /* Center the red symbol */
  color: red; /* Set the color of the red symbol */
  font-size: 10px; /* Adjust the font size as needed */
}
 



.bell-icon {
  width: 24px;
  height: 24px;
  color: white;
  stroke:white; /* Adjust the stroke color */
  /* Adjust the stroke width */
  fill: none; /* Remove fill if you want a hollow icon */
}

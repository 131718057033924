/* Style for the table */
.upcoming-consultations-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .upcoming-consultations-table th, .upcoming-consultations-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .upcoming-consultations-table th {
    background-color: #f2f2f2;
  }
  
  /* Style for the cancel button */
  .cancel-button {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .cancel-button:hover {
    background-color: #c0392b;
  }

 


  
.payment-container {
    display: flex;
    justify-content: space-between;
  }
  
  .payment-details {
    flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  }
  
  .payment-image {
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
  }
  
  .details {
    margin-top: 20px;
  }
  
  .checkout {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkout-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .checkout-button:hover {
    background-color: #0056b3;
  }
  
/* DoctorAvailabilityinWeekStyles.css */

.doctor-availability-container {
    display: flex;
    justify-content: space-between;
  }
  
  .calendar-container {
    width: 80%; /* Adjust the width of the calendar */
    margin-right: 20px; /* Add margin to create space between the calendar and other elements */
  }
  
  .availability-details-container {
    width: 25%;
  }
  
  .calendar-container .react-calendar {
    width: 100%;
    height: 700px; /* Adjust the height of the calendar */
  }
  
  /* Add a custom style for the selected date in the calendar */
  .react-calendar__tile--active {
    background-color: #e0f7fa; /* Change the background color for the selected date */
    color: #000; /* Change the text color for the selected date */
  }
  
  /* Highlight the already allotted dates */
  .react-calendar__tile.has-slot {
    background-color: rgb(45, 0, 128); /* Change the background color for already allotted dates */
    color: white; /* Change the text color for already allotted dates */
  }
  
  /* Add a custom style for the highlighted dates */
  .highlighted-date {
    
    background-color:#a5d6a7; /* Define a background color for highlighted dates */
  color: #000; /* Define a text color for highlighted dates */
   /* Add border-radius for a circular appearance, if desired */
  }
  
  /* Add more custom styles as needed */
  
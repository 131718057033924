.chat-frame {
    border: none;
    width: 300px;
    height: 500px;
    background-color: lighten(#0074d9, 20);
    opacity: 0.85;
    padding: 20px;
    color: rgba(255, 255, 255, 0.9);
    position: fixed;
    bottom: 0;
    left: 70vw;
    right: 0;
    border: 2px solid #2eb1be;
  }
.submit-button {
    margin-bottom: 20px; /* Add space between Submit button and Resend OTP button */
}

.resend-button-container {
    display: flex;
    justify-content: center;
}

.resend-button {
    margin-top: 10px; /* Add space above the Resend OTP button */
    /* Add any other styles you want for the Resend OTP button */
}

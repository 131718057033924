/* registerStyles.css */

.imageholder {
    position: relative;
  }
  
  .background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/bckimage.webp'); 
    z-index: -1;
    
  }
  
  
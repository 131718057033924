/* AppointmentDetailsPage.css */

.appointment-details-container {
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
}

.patient-info {
  display: flex;
  align-items: center;
}

.patient-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.patient-details {
  max-width: 400px;
}

.action-buttons {
  margin-top: 20px;
}

.action-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
}

.action-button:hover {
  background-color: #0056b3;
}

.completed-consultations {
  margin-top: 40px;
}

.completed-consultations table {
  width: 100%;
  border-collapse: collapse;
}

.completed-consultations th,
.completed-consultations td {
  border: 1px solid #ddd;
  padding: 8px;
}

.completed-consultations th {
  background-color: #f2f2f2;
}

.completed-consultations tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.completed-consultations tbody tr:hover {
  background-color: #ddd;
}

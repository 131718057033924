/* PrescriptionPage.css */

.prescription-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .prescription-form {
    display: grid;
    gap: 16px;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  input,
  select,
  textarea,
  .react-datepicker__input-container {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  button.add-prescription-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button.add-prescription-button:hover {
    background-color: #45a049;
  }
  
  /* Customize the appearance of the date picker */
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    width: 100%;
  }
  
  .react-datepicker {
    font-size: 14px;
  }


  .validation-error {
    color: red;
    margin-bottom: 50px; /* Add some space below the error message */
  }
  
  
  
  /* Optional: Style for additional components like react-datepicker */
  
.prescription-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  
}

.prescription-details {
  line-height: 1.6;
}

.back-link {
  margin-bottom: 20px;
}

.pagination {
  margin-top: 20px;
}

.pagination-list {
  list-style: none;
  padding: 0;
}

.pagination-list li {
  display: inline-block;
  margin-right: 10px;
}

.pagination-list button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-list button.active {
  background-color: #0056b3;
}
